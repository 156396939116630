"use strict"

let cookies = false;

let current = []

const cookieName = "chatwith_storage"

let protoType = {
  "calls": []
}


export const setCookies = (value) => {
  cookies = value
  if(value === false){
    const items = { ...localStorage };
  }
}


export const setLocalStorage = (key, value,block='calls') => {
  if(cookies === true){
    saveItemToStorage(key, value, getAll());
  }
}


export const getLocalStorage = (key, block='calls') => {

  if(cookies === false){
    return false
  }

  const data = key ? getItemFromStorage(key, getAll()) : "";
  if(data && data.hasOwnProperty("payload") === true){
    return data.payload
  }
  return null

}


const getAll = () => {
  const data = localStorage.getItem(cookieName);
  if(data === null){
    return protoType
  }
  else{
    return JSON.parse(data)
  }
}

const getItemFromStorage = (id, storageObject, block='calls') => {
  const data =  storageObject[block].find((item) => id == item.id)
  return data;

}

const saveItemToStorage = (id, payload, storageObject, block='calls') => {
  const existingItem = getItemFromStorage(id, storageObject,'calls')
  if(existingItem){
      const index = storageObject[block].findIndex((obj) => obj.id == id)
      if (index > -1) {
        storageObject[block].splice(index, 1);
      }
  }
  storageObject[block].push({id:id,payload:payload})
  localStorage.setItem(cookieName, JSON.stringify(storageObject))
}

export const updateVals = (savedForm, newForm, ischatform) => {

  if(ischatform === true){
    return newForm
  }

  if(!savedForm){
    return newForm
  }
  //return newForm

  newForm.items.forEach((i,index) => {
    const data = savedForm.items.find(savedItem => savedItem.label == i.label)
    if(data){
      newForm.items[index].val = data.val
    }
  })
  return newForm

}

export const usedToChat = () => {
  if(cookies){
    localStorage.setItem('tochatuser',"true")
  }
}

export const isReturnUser = () => {
  if(cookies){
    if(localStorage.getItem('tochatuser')){
      return true
    }
    else if(localStorage.getItem(cookieName)){
      return true
    }
  }
  return false
}


